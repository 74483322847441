import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormConfig } from '../../models/Form';
import TemplateFormService from '../../services/TemplateFormService';
import { Option } from '../../components/Option';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { FormType, FormTypeKeys } from '../../models/FormTypes';
import FormTemplateList from './FormTemplateList';
import useDebounce from '../../hooks/useDebounce';
import { ApiResponse } from '../../models/ApiResponse';
import { useTranslation } from 'react-i18next';
import FilterTag, { FilterSelectMode } from '../../components/shared/tags/FilterTag';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import { nextTick } from '../../utils/ReactUtils';

const FormTemplates: FC = () => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const [loading, setLoading] = useState(false);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [forms, setForms] = useState<ApiResponse<FormConfig[]> | null>(null);
  const [sortBy, setSortBy] = useState('-modifiedUtc,+title');
  const [currentPage, setCurrentPage] = useState(1);
  const [formSearchPhrase, setFormSearchPhrase] = useState('');
  const debouncedSearchTerm = useDebounce(formSearchPhrase, 500);
  const { t } = useTranslation(['common', 'organisation', 'form-builder']);

  const navigate = useNavigate();

  useEffect(() => {
    setForms(null);
    setCurrentPage(1);
  }, [debouncedSearchTerm]);

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const [statusFilters, setStatusFilters] = useState<Option<string, boolean>[]>([]);

  useEffect(() => {
    setStatusFilters((prev) => {
      return [
        {
          id: 'active',
          value: prev.find((x) => x.id === 'active')?.value || false,
          text: t('organisation:activities.filter.active'),
        },
        {
          id: 'archived',
          value: prev.find((x) => x.id === 'archived')?.value || false,
          text: t('organisation:activities.filter.archived'),
        },
      ];
    });
  }, [t]);

  const [typeFilters, setTypeFilters] = useState<Option<(typeof FormType)[keyof typeof FormType], boolean>[]>([
    {
      id: FormType.Asset,
      text: t(FormTypeKeys[FormType.Asset]),
      value: true,
    },
    {
      id: FormType.Startup,
      text: t(FormTypeKeys[FormType.Startup]),
      value: true,
    },
    {
      id: FormType.SubForm,
      text: t(FormTypeKeys[FormType.SubForm]),
      value: true,
    },
    {
      id: FormType.SubFormWithApproval,
      text: t(FormTypeKeys[FormType.SubFormWithApproval]),
      value: true,
    },
    {
      id: FormType.Default,
      text: t(FormTypeKeys[FormType.Default]),
      value: true,
    },
    {
      id: FormType.Document,
      text: t(FormTypeKeys[FormType.Document]),
      value: true,
    },
  ]);

  const onSortBy = (expression: string) => {
    setForms(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const shouldIncludeArchived = useMemo(() => {
    if (!statusFilters.find((filter) => filter.id === 'archived')?.value && !statusFilters.find((filter) => filter.id === 'active')?.value) {
      return true;
    }
    return statusFilters.find((filter) => filter.id === 'archived')?.value;
  }, [statusFilters]);

  const shouldIncludeActive = useMemo(() => {
    if (!statusFilters.find((filter) => filter.id === 'archived')?.value && !statusFilters.find((filter) => filter.id === 'active')?.value) {
      return true;
    }
    return statusFilters.find((filter) => filter.id === 'active')?.value;
  }, [statusFilters]);

  const formsFilter = useMemo(
    () => ({
      latestOnly: true,
      includeArchived: shouldIncludeArchived,
      includeActive: shouldIncludeActive,
      searchTerm: debouncedSearchTerm,
      types: typeFilters
        .filter((filter) => filter.value)
        .map((filter) => filter.id)
        .flat(),
      sortBy: sortBy,
      pageSize: 15,
      pageNumber: currentPage,
    }),
    [currentPage, debouncedSearchTerm, shouldIncludeActive, shouldIncludeArchived, sortBy, typeFilters],
  );

  const filterForms = useCallback(() => {
    const scrollTop = pageContentRef.current?.scrollTop || 0;

    setLoading(true);
    templateService
      .getAllTemplatesPaged(formsFilter, false)
      .then((res) => {
        setForms((prev) => {
          const formsPaged = { ...res };
          if (prev?.data && formsFilter.pageNumber > 1) {
            formsPaged.data = [...prev.data, ...res.data];
          }
          return formsPaged;
        });

        nextTick(() => {
          pageContentRef.current?.scrollTo({ top: scrollTop });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [formsFilter, templateService]);

  useEffect(() => {
    filterForms();
  }, [filterForms]);

  const doArchive = (formId: string) => {
    templateService.archiveTemplate(formId).then(() => {
      filterForms();
    });
  };

  const doRestore = (formId: string) => {
    templateService.restoreTemplate(formId).then(() => {
      filterForms();
    });
  };

  const applyTypesFilter = (filters: Option<(typeof FormType)[keyof typeof FormType], boolean>[]) => {
    setCurrentPage(1);
    setTypeFilters(filters);
  };

  const applyStatusFilter = (filters: Option<string, boolean>[]) => {
    setCurrentPage(1);
    setStatusFilters(filters);
  };

  return (
    <div className="flex h-full flex-col pt-6" ref={pageContentRef}>
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('organisation:tabs.forms')}</Heading>
        <div className="flex justify-between gap-4">
          <div className="w-80">
            <SearchInput
              placeholder={t('common:list.filter.search')}
              value={formSearchPhrase}
              onChange={(e) => {
                setCurrentPage(1);
                setFormSearchPhrase(e.target.value);
              }}
              data-cy="search-template"
            />
          </div>
          <Button
            onClick={() =>
              currentClient ? navigate(`/clients/${currentClient.id}/form-builder`) : navigate(`/builder/${currentTenantId}/form-builder`)
            }
            type={ButtonType.PRIMARY}
            data-cy="create-new-template"
          >
            {t('organisation:activities.create')}
          </Button>
        </div>
      </div>
      <div className="bg-background-1 -mx-6 h-full p-8 pt-4">
        <div className="flex gap-2">
          <FilterTag
            tag={{
              id: 'type',
              text: t(`common:list.filter.type`),
              value: 'type',
            }}
            onFiltersChange={applyTypesFilter}
            mode={FilterSelectMode.Multi}
            options={typeFilters}
          />
          <FilterTag
            tag={{
              id: 'status',
              text: t(`common:list.filter.status`),
              value: 'status',
            }}
            mode={FilterSelectMode.Multi}
            onFiltersChange={applyStatusFilter}
            options={statusFilters}
          />
        </div>
        <div className="flex-grow pt-4">
          {forms && (
            <FormTemplateList
              onArchive={doArchive}
              onRestore={doRestore}
              formsPaged={forms}
              sortBy={sortBy}
              onSort={onSortBy}
              onLoadMore={setCurrentPage}
              isLoading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormTemplates;
